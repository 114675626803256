import React from "react";
import '../styles/header.scss';

export default class Header extends React.Component {
    render () {
        return <div>
            <h1 className='header'>Zachary Griswold</h1>
        </div>;
    }
}

